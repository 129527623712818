body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url(./images/back.jpeg); */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#fluid{
  /* justify-content: center;
  align-items: center; */
  /* margin: auto; */
  display: block;
  text-align: center;
  

}
/* side navbar */
.offcanvas-body{ 
   margin: auto;
  text-align: center;
  text-decoration: none;
}
.text{
  text-decoration: none;
}
.offcanvas-body ul li{
  width: 210px;
  text-decoration: none;
  list-style: none;
  text-align: center;
  color: black;
}
.offcanvas-body ul li:hover{
  width: 210px;
  background-color: rgb(12, 148, 190);
  color: black;
}
.navtogglebotton:focus,
.navtogglebotton:active{
    outline: none;
    box-shadow: none;
}
/* //cards css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif
}
#body{
  min-width: 90vw;
  min-height: 150vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: center;
  gap: 10px 50px;
  padding: 30px 50px;
}
.container-fluid .card
{
  position: relative;
  display: flex;
    max-width: 330px;
    height: 270px;
    margin: 30px 10px;
    padding: 20px 15px;
    box-shadow: 0 5px 20px rgba(0,0,0,0.5);
    transition: 0.3s ease-in-out;
}
@media (max-width: 445px) {
  .container-fluid .card {
    max-width: 300px;
    height: 250px;
  }
}
@media (max-width: 445px) {
  .container-fluid .card .imgContainer {
    max-width: 230px;
    height: 215px;
  }
}
@media (max-width: 400px) {
  .container-fluid .card {
    max-width: 290px;
    height: 255px;
  }
}
@media (max-width: 400px) {
  .container-fluid .card .imgContainer {
    max-width: 200px;
    height: 180px;
  }
}
@media (max-width: 400px) {
  .container-fluid .card:hover {
    height: 1000px;
  }
}
.container-fluid .card:hover
{ height: 460px;}
.container-fluid .card .imgContainer
{
    position: relative;
    width:280px;
    height:200px;
    top:-50px;
    left:0px;
    overflow: hidden;
    transition: 0.5s;
    z-index: 1;
    box-shadow: 0 5px 20px rgba(0,0,0,0.2);
}
.container-fluid .card:hover .imgContainer{
  top: -100px;
  scale: 0.75;
}
.container-fluid .card .imgContainer img
{
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.container-fluid .card .content{
  position: absolute;
  top: 200px;
  width: 100%;
  padding: 0px 30px;
  height: 39px;
  overflow: hidden;
  text-align: start;
  transition: 0.5s;
}
.container-fluid .card:hover .content{
  top: 130px;
  height: 350px;
}
@media (max-width: 330px){
    .container .card .imgContainer{
        left: -2px;
    }
}
#btn:hover{
  background-color: rgb(102, 192, 244);
  color:white
  } 
  #btn {
  background-color: white;
  color: rgb(102, 192, 244);
  border-color: rgb(102, 192, 244);
  border-radius: 20px;
  padding: 3px;
  }

/* //footer css */
.text-dark{
  text-decoration: none;
}
#footerbody{margin-top:20px;}
.site-footer {
    padding-top: 72px;
    /* background-color: #fff; */
}
@media (max-width: 768px) {
    .site-footer {
        padding-top: 48px;
    }
}
.footer-copyright {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 24px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: normal;
}
img, figure {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
.widget-title {
    margin-bottom: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e1e7ec;
    color: #9da9b9;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

#footer {
  background-image: url(images/footer1.png);
  /* background-attachment: fixed; */
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
}

/* Additional style for hover effect (optional) */
.container #card:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}
/**/

@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");

.light {
	background: #fff;
}

a, a:hover {
	text-decoration: none;
	transition: color 0.3s ease-in-out;
}
.btn{
  padding: 2px;
}
#pageHeaderTitle {
	margin: 2rem 0;
	text-transform: uppercase;
	text-align: center;
	font-size: 2.5rem;
}
/* 
//button css */
.social{
  position: fixed;
  height: 65vh;
  display:flex ;
  flex-direction: column;
  justify-content: center;
  transform: translate(-260px, 0);
  z-index: 1000;
  /* background-color: #373b44; */
}
.social a{
  color: #fff;
  background-color: black;
  /* background-color: rgba(233, 198, 198, 0.4); */
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  display: block;
  margin: 5px;
  padding: 10px;
  width: 300px;
  text-align: right;
  border-radius: 50px;
  transition: 1s;
  transition-property: transform;

}
.social a:hover{
  transform: translate(140px,0)
}
.social i{
  margin-left: 10px;
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.social a:nth-child(3) i{
  color: #1DA0F0
}

.social a:nth-child(5) i{
  color: #f80000
}

.social a:nth-child(4) i{
  color: transparent;
  background-image: linear-gradient(45deg, #FFDF9E, #E56969, #8A49A1);
  -webkit-background-clip: text;
  font-weight: 600;
}

.social a:nth-child(6) i{
  color: #0E47E3
}

.social a:nth-child(2) i{
  color: transparent;
  background-image: linear-gradient(45deg, #FFDF9E, #D00923, #DAF7A6);
  -webkit-background-clip: text;
  font-weight: 600;
}

.social a:nth-child(1) i{
  color: #09D01C
}

/* //contact css */

.contact-form-container {
  display: flex;
}

.map-container {
  flex: 1;
  margin: 20px;
  border-top: 8px solid  #5c0000; /* Color for the top border */
  border-left: 8px solid  #5c0000; /* Color for the left border */
  border-bottom: 8px solid #5c0000; /* Color for the bottom border */
}

.form-container {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.get-in-touch-heading {
  color:  #5c0000;
  font-size: 24px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#scroll{
  /* max-height: 100px;  */
  overflow-y: scroll !important;
}
.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px; /* Set max height for vertical scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  z-index: 1;
}

 .quote{
  margin-top: 25px;
  margin-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  color:#00ABF2 ;
  text-align: center;
 }
 .quote:hover{
  background-color: black;
  color:#fff;
 }

 #header{
  
  background: #000;
  background: linear-gradient(
    rgba(0,0,0,0.3),
    rgba(0,0,0,0.8)
  ),
  url(./images/Contactus.png);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
    /* opacity: 0.8; */
    height: 300px;
    /* width: 1345px; */
  
 }
 #about{
  
  background: #000;
  background: linear-gradient(
    rgba(0,0,0,0.3),
    rgba(0,0,0,0.8)
  ),
  url(./images/About.png);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* opacity: 0.8; */
  height: 300px;
  /* width: 1345px; */

}
#service{
  
  background: #000;
  background: linear-gradient(
    rgba(0,0,0,0.3),
    rgba(0,0,0,0.8)
  ),
  url(./images/Service.png);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* opacity: 0.8; */
  height: 300px;
  /* width: 1345px; */

}

 #header h1{
  font-weight: 600;
  /* color: #00ABF2; */
  margin-left: auto;
 }
 #parallex{
  background: #000;
  background: linear-gradient(
    rgba(0,0,0,0.3),
    rgba(0,0,0,0.8)
  ),
  url(./images/5.png);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
 }

 .parallex-header{
  background: #000;
  background: linear-gradient(
    rgba(0,0,0,0.3),
    rgba(0,0,0,0.7)
  ),
  url(./images/paralex.png);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
}

.header-content{
  padding: 0px 0 150px 0;

}
.parallexcontent{
 margin-top: -130px;
 background: #ffffff;
 box-shadow: 0 0 20px -6px rgba(0,0,0,0.2); 
 padding-bottom:20px ;
 /* height: 250px; */
}

.text-justify{
  text-align: justify;
margin-right: 50px;
margin-left: 50px;
font-family: Verdana, Geneva, Tahoma, sans-serif;
margin-top: 10px;
}
#section-title {
  text-align: center;
  color: black;
  margin-top: 20px;
  text-transform: uppercase;
}
#button:hover{
  background-color: #00ABF2;
  color:white
  }
  
  #button {
  background-color: white;
  color: #00ABF2;
  border-color: #00ABF2;
  border-radius: 50%;
  }

  /* //media query for parallex */
  @media (max-width: 986px) {
    #left{
      height: 300px;
    }
  }
  @media (max-width: 535px) {
    #left{
      height: 370px;
    }
  }
  #homepar{
    padding: 140px;
    font-size: 100px;
  }
  @media (max-width: 990px) {
    #homepar{
      padding: 120px;
    font-size: 80px
    }
  }
  @media (max-width: 530px) {
    #homepar{
      padding: 110px;
    font-size: 50px
    }
  }